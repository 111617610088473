<template>
    <div>
        <!-- <Chart ref="chart" :options="chart_options"></Chart> -->
        <highcharts
            ref="chart"
            class="hc"
            :options="chart_options"
        ></highcharts>

        <!-- <button @click="exportChart">Export Chart as PNG</button> -->
    </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import moment from 'moment'
import campaignMixin from '@/views/campaign/campaign-wrapper/_mixin'

const renderYAxis = () => {
    return {
        title: {
            text: ''
        },
        labels: {
            enabled: false
        },
        visible: true
    }
}

export default {
    // components: {
    //     Chart
    // },

    mixins: [campaignMixin],

    props: ['data_list', 'format_date', 'selected_trends'],

    data() {
        return {
            show_chart: true,
            chart_options: {
                chart: {
                    type: 'spline',
                    height: 240
                },
                title: {
                    text: ''
                },
                legend: {
                    enabled: false,
                    floating: true
                },
                xAxis: {
                    categories: [],
                    crosshair: true,
                    minTickInterval: 6,
                    tickInterval: 1,
                    endOnTick: true,
                    startOnTick: true,
                    labels: {
                        step: 1
                    }
                },
                tooltip: {
                    pointFormatter: null,
                    shared: true
                },
                yAxis: [],
                series: [],
                // exporting: {
                //     buttons: {
                //         contextButton: {
                //             enabled: true
                //         },
                //         customButton: {
                //             text: 'Click to see an error',
                //             onclick: function () {
                //                 console.log("I've been clicked")
                //             }
                //         }
                //     }
                // },
                rangeSelector: { enabled: false }
            },
            value: ''
        }
    },

    computed: {
        s__currency() {
            return this.$store.getters.app.s__currency
        }
    },

    watch: {
        selected_trends() {
            this.changeSeriesChart()
        },

        data_list() {
            const categories = this.data_list.map((item) =>
                moment(item.dimensions.stat_time_day).format(this.format_date)
            )
            this.chart_options.xAxis.categories = categories

            let step = 1

            if (categories.length <= 20) {
                step = 1
            } else if (categories.length > 20 && categories.length < 30) {
                step = 2
            } else if (categories.length >= 30 && categories.length < 50) {
                step = 4
            } else {
                step = (categories.length / 8).toFixed(0)
            }

            this.chart_options.xAxis.labels.step = step

            this.changeSeriesChart()
        }
    },

    mounted() {
        this.chart_options.xAxis.categories = this.data_list.map((item) =>
            moment(item.dimensions.stat_time_day).format(this.format_date)
        )

        const m__formatterColumn = (x, y) =>
            this.m__formatterColumn(x, y, this.s__currency)
        const p__getNameByTrend = this.p__getNameByTrend
        this.chart_options.tooltip.pointFormatter = function () {
            const point = this
            const series = point.series
            const y = m__formatterColumn(point.y || 0, series.name)
            return `${p__getNameByTrend(series.name)}: <b>${y}</b><br/>`
        }

        this.changeSeriesChart()
    },

    methods: {
        exportChart() {
            this.$refs.chart.chart.exportChart('image/png')
        },

        changeSeriesChart() {
            const series = []
            const yAxis = []
            let index = 0

            for (const item of this.selected_trends) {
                const trend = this.p__trend_basic_chart_options.find(
                    (t) => t.value === item
                )

                const data = this.data_list.map((it) => +it.metrics[item])

                series.push({
                    name: trend ? trend.value : '',
                    color: trend ? trend.color : 'red',
                    data,
                    yAxis: index,
                    marker: {
                        enabled: false,
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    }
                })

                yAxis.push(renderYAxis())

                index++
            }

            this.chart_options.series = series

            this.chart_options.yAxis = yAxis
        }
    }
}
</script>
