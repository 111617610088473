var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex items-center mb-2"},[_c('div',{staticClass:"bg-bg p-2 ml-auto rounded-lg cursor-pointer",on:{"click":function($event){return _vm.handleExportExcel(_vm.new_data_list, _vm.ranger_date)}}},[_c('el-tooltip',{staticClass:"item",attrs:{"content":_vm.$t(`common.export_excel`),"effect":"dark","placement":"top"}},[_c('ExcelSvg')],1)],1)]),_c('el-table',{attrs:{"max-height":"360","data":_vm.data_overview},on:{"sort-change":_vm.handleSortChange}},[_c('el-table-column',{attrs:{"width":"240","show-overflow-tooltip":true,"label":_vm.$t('common.ad_account'),"fixed":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v("ID: "+_vm._s(scope.row.advertiser_id))]),_c('p',[_vm._v(" "+_vm._s(scope.row.name)+" ")])]}}])}),_vm._l((_vm.p__trend_basic_chart_options),function(item,index){return _c('el-table-column',{key:index,attrs:{"width":"160","show-overflow-tooltip":true,"prop":item.value,"label":_vm.$t(`page.campaign.column_${item.value}`),"sortable":"custom","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-tooltip',{staticClass:"item",attrs:{"content":_vm.$t(`page.campaign.column_${item.value}`),"effect":"dark","placement":"top"}},[_c('div',{staticClass:"max-w-[360px]",attrs:{"slot":"content"},slot:"content"},[_c('p',{staticClass:"text-sm",domProps:{"innerHTML":_vm._s(
                                _vm.$t(`page.campaign.column_${item.value}`)
                            )}}),(
                                _vm.$t(
                                    `page.campaign.description_column_${item.value}`
                                ) !==
                                `page.campaign.description_column_${item.value}`
                            )?_c('p',{staticClass:"mt-1",domProps:{"innerHTML":_vm._s(
                                _vm.$t(
                                    `page.campaign.description_column_${item.value}`
                                )
                            )}}):_vm._e()]),_c('p',[_vm._v(" "+_vm._s(_vm.$t(`page.campaign.column_${item.value}`))+" ")])])]},proxy:true},{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s(_vm.m__formatterColumn( scope.row.metrics[item.value], item.value, _vm.currency ))+" ")])]}}],null,true)})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }