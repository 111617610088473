<template>
    <div>
        <div class="flex items-center mb-2 space-x-2">
            <el-select v-model="selected_option" class="w-[200px]" size="small">
                <el-option
                    v-for="item in option_list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </div>

        <ObjectiveTypeTable
            v-if="selected_option === 'objective_type'"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :ranger_date="ranger_date"
            :currency="currency"
        ></ObjectiveTypeTable>

        <OptimizationGoalTable
            v-if="selected_option === 'optimization_goal'"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :ranger_date="ranger_date"
            :currency="currency"
        ></OptimizationGoalTable>

        <BidStrategyTable
            v-if="selected_option === 'bid_strategy'"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :ranger_date="ranger_date"
            :currency="currency"
        ></BidStrategyTable>

        <AdEntityTypeTable
            v-if="selected_option === 'ad_entity_type'"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :ranger_date="ranger_date"
            :currency="currency"
        ></AdEntityTypeTable>
    </div>
</template>

<script>
import ObjectiveTypeTable from './objective-type-table'
import OptimizationGoalTable from './optimization-goal-table'
import BidStrategyTable from './bid-strategy-table'
import AdEntityTypeTable from './ad-entity-type-table'

export default {
    components: {
        AdEntityTypeTable,
        BidStrategyTable,
        ObjectiveTypeTable,
        OptimizationGoalTable
    },

    props: ['tiktok_account_id', 'advertiser_id', 'ranger_date', 'currency'],

    data() {
        return {
            selected_option: 'objective_type',
            option_list: [
                {
                    label: this.$t('common.objective_type'),
                    value: 'objective_type'
                },
                {
                    label: this.$t('page.campaign.optimization_goal'),
                    value: 'optimization_goal'
                },
                {
                    label: this.$t('page.campaign.bid_strategy'),
                    value: 'bid_strategy'
                },
                {
                    label: this.$t('page.campaign.ad_entity_type'),
                    value: 'ad_entity_type'
                }
            ]
        }
    }
}
</script>
