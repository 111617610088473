<template>
    <div v-loading="m__loading">
        <div v-if="pie_chart_options">
            <Chart
                v-show="show_pie_column && !hidden_chart"
                :options="pie_chart_options"
                :deepCopyOnUpdate="false"
            ></Chart>

            <NoData
                v-show="show_pie_column && hidden_chart"
                class="h-[240px] w-full"
            ></NoData>
        </div>

        <ColumnChart
            v-show="!show_pie_column"
            :gender_data="gender_data"
            :selected_metric="selected_metric"
        ></ColumnChart>
    </div>
</template>

<script>
import { getSyncReport } from '@/services/atosa-tiktok-ads/reporting'
import { Chart } from 'highcharts-vue'
import ColumnChart from './column-chart'
import moment from 'moment'

export default {
    components: {
        Chart,
        ColumnChart
    },

    props: [
        'chart_options',
        'tiktok_account_id',
        'advertiser_id',
        'ranger_date',
        'selected_metric'
    ],

    data() {
        return {
            hidden_chart: false,
            gender_data: [],
            show_pie_column: true,
            pie_chart_options: null
        }
    },

    watch: {
        selected_metric() {
            this.renderChart()
        },

        async ranger_date() {
            this.gender_data = await this.initData(this.ranger_date)

            this.renderChart()
        }
    },

    async mounted() {
        setTimeout(async () => {
            this.gender_data = await this.initData(this.ranger_date)

            this.renderChart()
        }, 2000)
    },

    methods: {
        renderChart() {
            if (
                [
                    'spend',
                    'impressions',
                    'clicks',
                    'conversion',
                    'real_time_result',
                    'result',
                    'real_time_conversion',
                    'video_play_actions',
                    'video_watched_2s',
                    'video_watched_6s',
                    'video_views_p25',
                    'video_views_p50',
                    'video_views_p50',
                    'video_views_p75',
                    'video_views_p100'
                ].some((metric) => metric === this.selected_metric)
            ) {
                this.show_pie_column = true
                this.formatPieChart()
            } else {
                this.show_pie_column = false
            }
        },

        async initData(ranger_date) {
            this.m__loading = true

            const response = await this.fetchSyncReport(ranger_date)

            this.m__loading = false

            return response
        },

        async fetchSyncReport(ranger_date) {
            await this.p__wait(678)

            try {
                const start_date = moment(ranger_date[0]).format('YYYY-MM-DD')
                const end_date = moment(ranger_date[1]).format('YYYY-MM-DD')

                const response = await getSyncReport(this.tiktok_account_id, {
                    page: 1,
                    page_size: 100,
                    report_type: 'AUDIENCE',
                    data_level: 'AUCTION_ADVERTISER',
                    start_date,
                    end_date,
                    metrics: this.chart_options.map((item) => item.value),
                    order_type: 'DESC',
                    advertiser_id: this.advertiser_id,
                    dimensions: ['platform']
                })
                if (response.code === 0 && response.data.list.length > 0) {
                    return response.data.list
                }
            } catch (error) {
                console.error(error)
            }

            return []
        },

        formatPieChart() {
            const temp_data = []

            let total = 0

            this.gender_data.forEach((element) => {
                total = total + +element.metrics[this.selected_metric]
            })

            this.gender_data.forEach((element) => {
                const name = element.dimensions.platform

                temp_data.push({
                    name,
                    y: +(
                        (+element.metrics[this.selected_metric] / total) *
                        100
                    ).toFixed(0),
                    value: this.p__formatNumberHasCommas(
                        +element.metrics[this.selected_metric]
                    ),
                    sliced: false,
                    selected: false
                })
            })

            this.hidden_chart = total === 0

            this.pie_chart_options = {
                chart: {
                    type: 'pie',
                    height: 220
                },
                title: {
                    text: ''
                },
                tooltip: {
                    pointFormatter() {
                        const point = this
                        return `<p>${point.value}</p><br><b>${point.y}%</b>`
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        borderWidth: 2,
                        dataLabels: {
                            enabled: true,
                            distance: 16,
                            format: '<b>{point.name}</b><br>{point.value}<br><b>{point.y}%</b>',
                            style: {
                                fontSize: '0.65rem',
                                textOutline: 'none',
                                opacity: 0.6
                            },
                            filter: {
                                operator: '>',
                                property: 'y',
                                value: 5
                            }
                        }
                    }
                },
                series: [
                    {
                        name: 'Percentage',
                        colorByPoint: true,
                        data: temp_data
                    }
                ]
            }
        }
    }
}
</script>
