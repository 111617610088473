<template>
    <div>
        <el-table
            v-loading="m__loading"
            max-height="360"
            :data="data_list"
            @sort-change="handleSortChange"
        >
            <el-table-column
                width="240"
                :show-overflow-tooltip="true"
                :label="$t('page.campaign.bid_strategy')"
                :fixed="true"
            >
                <template #default="scope">
                    <p>
                        {{ scope.row.title }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column
                v-for="(item, index) in p__trend_basic_chart_options"
                :key="index"
                width="160"
                :show-overflow-tooltip="true"
                :prop="item.value"
                sortable="custom"
                :label="$t(`page.campaign.column_${item.value}`)"
                align="right"
            >
                <template v-slot:header>
                    <el-tooltip
                        class="item"
                        :content="$t(`page.campaign.column_${item.value}`)"
                        effect="dark"
                        placement="top"
                    >
                        <div slot="content" class="max-w-[360px]">
                            <p
                                class="text-sm"
                                v-html="
                                    $t(`page.campaign.column_${item.value}`)
                                "
                            ></p>

                            <p
                                v-if="
                                    $t(
                                        `page.campaign.description_column_${item.value}`
                                    ) !==
                                    `page.campaign.description_column_${item.value}`
                                "
                                class="mt-1"
                                v-html="
                                    $t(
                                        `page.campaign.description_column_${item.value}`
                                    )
                                "
                            ></p>
                        </div>

                        <p>
                            {{ $t(`page.campaign.column_${item.value}`) }}
                        </p>
                    </el-tooltip>
                </template>

                <template #default="scope">
                    <p>
                        {{
                            m__formatterColumn(
                                scope.row[item.value],
                                item.value,
                                currency
                            )
                        }}
                    </p>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { getSyncReport } from '@/services/atosa-tiktok-ads/reporting'
import moment from 'moment'
import campaignMixin from '@/views/campaign/campaign-wrapper/_mixin'

export default {
    mixins: [campaignMixin],

    props: ['tiktok_account_id', 'advertiser_id', 'ranger_date', 'currency'],

    data() {
        return {
            data_list: [],
            bid_strategy_list: [
                {
                    title: this.$t('page.campaign.bid_strategy_cost_cap'),
                    value: 'BID_STRATEGY_COST_CAP'
                },
                {
                    title: this.$t('page.campaign.bid_strategy_bid_cap'),
                    value: 'BID_STRATEGY_BID_CAP'
                },
                {
                    title: this.$t('page.campaign.bid_strategy_max_conversion'),
                    value: 'BID_STRATEGY_MAX_CONVERSION'
                },
                {
                    title: this.$t('page.campaign.bid_strategy_lowest_cost'),
                    value: 'BID_STRATEGY_LOWEST_COST'
                },
                {
                    title: this.$t('page.campaign.bid_strategy_highest_value'),
                    value: 'BID_STRATEGY_HIGHEST_VALUE'
                },
                {
                    title: this.$t('page.campaign.bid_strategy_gmv'),
                    value: 'BID_STRATEGY_GMV'
                },
                {
                    title: this.$t('page.campaign.bid_strategy_minimum_roas'),
                    value: 'BID_STRATEGY_MINIMUM_ROAS'
                }
            ]
        }
    },

    watch: {
        ranger_date() {
            this.fetchReport()
        }
    },

    mounted() {
        this.fetchReport()
    },

    methods: {
        handleSortChange({ column, prop, order }) {
            if (order === 'descending') {
                this.data_list.sort((a, b) =>
                    +a[prop] > +b[prop] ? 1 : +b[prop] > +a[prop] ? -1 : 0
                )
            } else {
                this.data_list.sort((a, b) =>
                    +a[prop] < +b[prop] ? 1 : +b[prop] < +a[prop] ? -1 : 0
                )
            }
        },

        async fetchReport() {
            await this.p__wait(1234)

            this.m__loading = true

            try {
                const start_date = moment(this.ranger_date[0]).format(
                    'YYYY-MM-DD'
                )
                const end_date = moment(this.ranger_date[1]).format(
                    'YYYY-MM-DD'
                )

                const temp_data_list = []

                await Promise.all(
                    this.bid_strategy_list.map(async (item) => {
                        const response = await getSyncReport(
                            this.tiktok_account_id,
                            {
                                page: 1,
                                page_size: 1,
                                report_type: 'BASIC',
                                data_level: 'AUCTION_CAMPAIGN',
                                start_date,
                                end_date,
                                metrics: this.p__trend_basic_chart_options.map(
                                    (item) => item.value
                                ),
                                order_type: 'DESC',
                                advertiser_id: this.advertiser_id,
                                dimensions: ['campaign_id'],
                                enable_total_metrics: true,
                                filtering: [
                                    {
                                        filter_value: JSON.stringify([
                                            item.value
                                        ]),
                                        field_name: 'bid_strategy',
                                        filter_type: 'IN'
                                    }
                                ]
                            }
                        )

                        if (
                            response.data.total_metrics.spend !== '-' &&
                            response.data.total_metrics.spend !== '0'
                        ) {
                            temp_data_list.push({
                                title: item.title,
                                value: item.value,
                                ...response.data.total_metrics
                            })
                        }
                    })
                )

                this.data_list = temp_data_list
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        }
    }
}
</script>
