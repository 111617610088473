<template>
    <Chart v-if="column_chart_options" :options="column_chart_options"></Chart>
</template>

<script>
import { Chart } from 'highcharts-vue'

export default {
    components: {
        Chart
    },

    props: ['gender_data', 'selected_metric'],

    data() {
        return {
            column_chart_options: null
        }
    },

    watch: {
        selected_metric() {
            this.formatColumnChart()
        },

        gender_data() {
            this.formatColumnChart()
        }
    },

    methods: {
        formatColumnChart() {
            const temp_data = []

            this.gender_data.forEach((element) => {
                const name = element.dimensions.device_brand_id
                const value = +element.metrics[this.selected_metric]
                if (value > 0) {
                    temp_data.push([name, value])
                }
            })

            temp_data.sort((a, b) => b[1] - a[1])

            this.column_chart_options = {
                chart: {
                    type: 'column',
                    height: 220
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        autoRotation: [-45, -90],
                        style: {
                            fontSize: '12px'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    pointFormatter() {
                        const point = this
                        return `<b>${point.y}</b>`
                    }
                },
                series: [
                    {
                        colorByPoint: true,
                        groupPadding: 0,
                        data: temp_data,
                        dataLabels: {
                            enabled: true,
                            rotation: -90,
                            color: '#FFFFFF',
                            inside: true,
                            verticalAlign: 'top',
                            format: '{point.y:.1f}', // one decimal
                            y: 10, // 10 pixels down from the top
                            style: {
                                fontSize: '12px'
                            }
                        }
                    }
                ]
            }
        }
    }
}
</script>
