<template>
    <div>
        <el-table
            v-loading="m__loading"
            max-height="360"
            :data="data_list"
            @sort-change="handleSortChange"
        >
            <el-table-column
                width="240"
                :show-overflow-tooltip="true"
                :label="$t('page.campaign.optimization_goal')"
                :fixed="true"
            >
                <template #default="scope">
                    <p>
                        {{ scope.row.title }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column
                v-for="(item, index) in p__trend_basic_chart_options"
                :key="index"
                width="160"
                :show-overflow-tooltip="true"
                :prop="item.value"
                sortable="custom"
                :label="$t(`page.campaign.column_${item.value}`)"
                align="right"
            >
                <template v-slot:header>
                    <el-tooltip
                        class="item"
                        :content="$t(`page.campaign.column_${item.value}`)"
                        effect="dark"
                        placement="top"
                    >
                        <div slot="content" class="max-w-[360px]">
                            <p
                                class="text-sm"
                                v-html="
                                    $t(`page.campaign.column_${item.value}`)
                                "
                            ></p>

                            <p
                                v-if="
                                    $t(
                                        `page.campaign.description_column_${item.value}`
                                    ) !==
                                    `page.campaign.description_column_${item.value}`
                                "
                                class="mt-1"
                                v-html="
                                    $t(
                                        `page.campaign.description_column_${item.value}`
                                    )
                                "
                            ></p>
                        </div>

                        <p>
                            {{ $t(`page.campaign.column_${item.value}`) }}
                        </p>
                    </el-tooltip>
                </template>

                <template #default="scope">
                    <p>
                        {{
                            m__formatterColumn(
                                scope.row[item.value],
                                item.value,
                                currency
                            )
                        }}
                    </p>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { getSyncReport } from '@/services/atosa-tiktok-ads/reporting'
import moment from 'moment'
import campaignMixin from '@/views/campaign/campaign-wrapper/_mixin'

export default {
    mixins: [campaignMixin],

    props: ['tiktok_account_id', 'advertiser_id', 'ranger_date', 'currency'],

    data() {
        return {
            data_list: [],
            optimization_goal_list: [
                {
                    value: 'CLICK',
                    title: this.$t('common.click')
                },
                {
                    value: 'CONVERT',
                    title: this.$t('common.conversion')
                },
                {
                    value: 'INSTALL',
                    title: this.$t('page.campaign.install')
                },
                {
                    value: 'IN_APP_EVENT',
                    title: this.$t('page.campaign.in_app_event')
                },
                {
                    value: 'SHOW',
                    title: this.$t('common.impression')
                },
                {
                    value: 'VIDEO_VIEW',
                    title: this.$t('page.campaign.video_view')
                },
                {
                    value: 'REACH',
                    title: this.$t('common.reach')
                },
                {
                    value: 'LEAD_GENERATION',
                    title: this.$t(
                        'page.campaign.optimization_goal_lead_generation'
                    )
                },
                {
                    value: 'FOLLOWERS',
                    title: this.$t('page.campaign.followers')
                },
                {
                    value: 'VALUE',
                    title: this.$t('page.campaign.optimization_goal_gmv')
                },
                {
                    value: 'GMV',
                    title: 'GMV'
                },
                {
                    value: 'MT_LIVE_ROOM',
                    title: this.$t('page.campaign.viewer_retention')
                },
                {
                    value: 'PRODUCT_CLICK_IN_LIVE',
                    title: this.$t('page.campaign.product_click_in_live')
                },
                {
                    value: 'ENGAGED_VIEW',
                    title: this.$t('page.campaign.column_engaged_view')
                },
                {
                    value: 'ENGAGED_VIEW_FIFTEEN',
                    title: this.$t('page.campaign.column_engaged_view_15s')
                },
                {
                    value: 'TRAFFIC_LANDING_PAGE_VIEW',
                    title: this.$t('page.campaign.traffic_landing_page_view')
                }
            ]
        }
    },

    watch: {
        ranger_date() {
            this.fetchReport()
        }
    },

    mounted() {
        this.fetchReport()
    },

    methods: {
        handleSortChange({ column, prop, order }) {
            if (order === 'descending') {
                this.data_list.sort((a, b) =>
                    +a[prop] > +b[prop] ? 1 : +b[prop] > +a[prop] ? -1 : 0
                )
            } else {
                this.data_list.sort((a, b) =>
                    +a[prop] < +b[prop] ? 1 : +b[prop] < +a[prop] ? -1 : 0
                )
            }
        },

        async fetchReport() {
            await this.p__wait(1234)

            this.m__loading = true

            try {
                const start_date = moment(this.ranger_date[0]).format(
                    'YYYY-MM-DD'
                )
                const end_date = moment(this.ranger_date[1]).format(
                    'YYYY-MM-DD'
                )

                const temp_data_list = []

                await Promise.all(
                    this.optimization_goal_list.map(
                        async (optimization_goal) => {
                            const response = await getSyncReport(
                                this.tiktok_account_id,
                                {
                                    page: 1,
                                    page_size: 1,
                                    report_type: 'BASIC',
                                    data_level: 'AUCTION_CAMPAIGN',
                                    start_date,
                                    end_date,
                                    metrics:
                                        this.p__trend_basic_chart_options.map(
                                            (item) => item.value
                                        ),
                                    order_type: 'DESC',
                                    advertiser_id: this.advertiser_id,
                                    dimensions: ['campaign_id'],
                                    enable_total_metrics: true,
                                    filtering: [
                                        {
                                            filter_value: JSON.stringify([
                                                optimization_goal.value
                                            ]),
                                            field_name: 'optimization_goal',
                                            filter_type: 'IN'
                                        }
                                    ]
                                }
                            )

                            if (
                                response.data.total_metrics.spend !== '-' &&
                                response.data.total_metrics.spend !== '0'
                            ) {
                                temp_data_list.push({
                                    title: optimization_goal.title,
                                    value: optimization_goal.value,
                                    ...response.data.total_metrics
                                })
                            }
                        }
                    )
                )

                this.data_list = temp_data_list
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        }
    }
}
</script>
