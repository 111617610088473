<template>
    <div class="">
        <div class="border-lg p-4 rounded-lg bg-[#f4f4f4]">
            <el-select
                v-model="selected_metric"
                class="w-[240px] mb-2 z-30"
                size="small"
            >
                <el-option-group
                    v-for="(group, index) in p__view_data_metric_options"
                    :key="index"
                    :label="group.label"
                >
                    <el-option
                        v-for="item in group.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-option-group>
            </el-select>

            <div class="flex space-x-4">
                <div class="p-4 rounded-xl flex-1 bg-white">
                    <p class="text-sm mb-2">
                        {{ $t('common.age_and_gender') }}
                    </p>

                    <AgeGenderChart
                        class="w-full"
                        :chart_options="chart_options"
                        :tiktok_account_id="tiktok_account_id"
                        :advertiser_id="advertiser_id"
                        :ranger_date="ranger_date"
                        :selected_metric="selected_metric"
                    ></AgeGenderChart>

                    <p
                        v-if="switch_compare"
                        class="text-center mt-2 text-sm italic"
                    >
                        {{ ranger_date[0] | f__format_moment(format_date) }} -
                        {{ ranger_date[1] | f__format_moment(format_date) }}
                    </p>

                    <AgeGenderChart
                        v-if="switch_compare"
                        class="mt-4 w-full"
                        :chart_options="chart_options"
                        :tiktok_account_id="tiktok_account_id"
                        :advertiser_id="advertiser_id"
                        :ranger_date="compare_ranger_date"
                        :selected_metric="selected_metric"
                    ></AgeGenderChart>

                    <p
                        v-if="switch_compare"
                        class="text-center mt-2 text-sm italic"
                    >
                        {{
                            compare_ranger_date[0]
                                | f__format_moment(compare_format_date)
                        }}
                        -
                        {{
                            compare_ranger_date[1]
                                | f__format_moment(compare_format_date)
                        }}
                    </p>
                </div>

                <div class="p-4 rounded-xl flex-1 bg-white">
                    <p class="text-sm mb-2">
                        {{ $t('common.operating_system') }}
                    </p>

                    <OpSystemChart
                        class="w-full"
                        :chart_options="chart_options"
                        :tiktok_account_id="tiktok_account_id"
                        :advertiser_id="advertiser_id"
                        :ranger_date="ranger_date"
                        :selected_metric="selected_metric"
                    ></OpSystemChart>

                    <p
                        v-if="switch_compare"
                        class="text-center mt-2 text-sm italic"
                    >
                        {{ ranger_date[0] | f__format_moment(format_date) }} -
                        {{ ranger_date[1] | f__format_moment(format_date) }}
                    </p>

                    <OpSystemChart
                        v-if="switch_compare"
                        :chart_options="chart_options"
                        class="mt-4 w-full"
                        :tiktok_account_id="tiktok_account_id"
                        :advertiser_id="advertiser_id"
                        :ranger_date="compare_ranger_date"
                        :selected_metric="selected_metric"
                    ></OpSystemChart>

                    <p
                        v-if="switch_compare"
                        class="text-center mt-2 text-sm italic"
                    >
                        {{
                            compare_ranger_date[0]
                                | f__format_moment(compare_format_date)
                        }}
                        -
                        {{
                            compare_ranger_date[1]
                                | f__format_moment(compare_format_date)
                        }}
                    </p>
                </div>

                <div class="p-4 rounded-xl flex-1 bg-white">
                    <p class="text-sm mb-2">
                        {{ $t('common.device') }}
                    </p>

                    <DeviceBrandChart
                        class="w-full"
                        :device_model_options="device_model_options"
                        :chart_options="chart_options"
                        :tiktok_account_id="tiktok_account_id"
                        :advertiser_id="advertiser_id"
                        :ranger_date="ranger_date"
                        :selected_metric="selected_metric"
                    ></DeviceBrandChart>

                    <p
                        v-if="switch_compare"
                        class="text-center mt-2 text-sm italic"
                    >
                        {{ ranger_date[0] | f__format_moment(format_date) }} -
                        {{ ranger_date[1] | f__format_moment(format_date) }}
                    </p>

                    <DeviceBrandChart
                        v-if="switch_compare"
                        :device_model_options="device_model_options"
                        :chart_options="chart_options"
                        class="mt-4 w-full"
                        :tiktok_account_id="tiktok_account_id"
                        :advertiser_id="advertiser_id"
                        :ranger_date="compare_ranger_date"
                        :selected_metric="selected_metric"
                    ></DeviceBrandChart>

                    <p
                        v-if="switch_compare"
                        class="text-center mt-2 text-sm italic"
                    >
                        {{
                            compare_ranger_date[0]
                                | f__format_moment(compare_format_date)
                        }}
                        -
                        {{
                            compare_ranger_date[1]
                                | f__format_moment(compare_format_date)
                        }}
                    </p>
                </div>

                <div class="p-4 rounded-xl flex-1 bg-white">
                    <p class="text-sm mb-2">
                        {{ $t('common.network') }}
                    </p>

                    <NetworkChart
                        class="w-full"
                        :chart_options="chart_options"
                        :tiktok_account_id="tiktok_account_id"
                        :advertiser_id="advertiser_id"
                        :ranger_date="ranger_date"
                        :selected_metric="selected_metric"
                    ></NetworkChart>

                    <p
                        v-if="switch_compare"
                        class="text-center mt-2 text-sm italic"
                    >
                        {{ ranger_date[0] | f__format_moment(format_date) }} -
                        {{ ranger_date[1] | f__format_moment(format_date) }}
                    </p>

                    <NetworkChart
                        v-if="switch_compare"
                        :chart_options="chart_options"
                        class="mt-4 w-full"
                        :tiktok_account_id="tiktok_account_id"
                        :advertiser_id="advertiser_id"
                        :ranger_date="compare_ranger_date"
                        :selected_metric="selected_metric"
                    ></NetworkChart>

                    <p
                        v-if="switch_compare"
                        class="text-center mt-2 text-sm italic"
                    >
                        {{
                            compare_ranger_date[0]
                                | f__format_moment(compare_format_date)
                        }}
                        -
                        {{
                            compare_ranger_date[1]
                                | f__format_moment(compare_format_date)
                        }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AgeGenderChart from './age-gender-chart'
import OpSystemChart from './op-system-chart'
import NetworkChart from './network-chart'
import DeviceBrandChart from './device-brand-chart'

export default {
    components: {
        AgeGenderChart,
        DeviceBrandChart,
        NetworkChart,
        OpSystemChart
    },

    props: [
        'device_model_options',
        'advertiser_id',
        'tiktok_account_id',
        'ranger_date',
        'format_date',
        'switch_compare',
        'compare_ranger_date',
        'compare_format_date'
    ],

    data() {
        const chart_options = []

        this.p__view_data_metric_options.forEach((item) => {
            item.options.forEach((it) => {
                chart_options.push(it)
            })
        })
        return {
            chart_options,
            selected_metric: this.p__basic_metrics[0]
        }
    }
}
</script>
