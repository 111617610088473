var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.m__loading),expression:"m__loading"}],attrs:{"max-height":"360","data":_vm.data_list},on:{"sort-change":_vm.handleSortChange}},[_c('el-table-column',{attrs:{"width":"240","show-overflow-tooltip":true,"label":_vm.$t('page.campaign.bid_strategy'),"fixed":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s(scope.row.title)+" ")])]}}])}),_vm._l((_vm.p__trend_basic_chart_options),function(item,index){return _c('el-table-column',{key:index,attrs:{"width":"160","show-overflow-tooltip":true,"prop":item.value,"sortable":"custom","label":_vm.$t(`page.campaign.column_${item.value}`),"align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-tooltip',{staticClass:"item",attrs:{"content":_vm.$t(`page.campaign.column_${item.value}`),"effect":"dark","placement":"top"}},[_c('div',{staticClass:"max-w-[360px]",attrs:{"slot":"content"},slot:"content"},[_c('p',{staticClass:"text-sm",domProps:{"innerHTML":_vm._s(
                                _vm.$t(`page.campaign.column_${item.value}`)
                            )}}),(
                                _vm.$t(
                                    `page.campaign.description_column_${item.value}`
                                ) !==
                                `page.campaign.description_column_${item.value}`
                            )?_c('p',{staticClass:"mt-1",domProps:{"innerHTML":_vm._s(
                                _vm.$t(
                                    `page.campaign.description_column_${item.value}`
                                )
                            )}}):_vm._e()]),_c('p',[_vm._v(" "+_vm._s(_vm.$t(`page.campaign.column_${item.value}`))+" ")])])]},proxy:true},{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s(_vm.m__formatterColumn( scope.row[item.value], item.value, _vm.currency ))+" ")])]}}],null,true)})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }