<template>
    <div class="">
        <div class="border-lg p-4 rounded-lg bg-[#f4f4f4]">
            <div>
                <div class="flex items-center mb-2 space-x-2">
                    <el-select
                        v-model="selected_dimension"
                        class="w-[200px]"
                        size="small"
                    >
                        <el-option
                            v-for="item in dimension_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>

                    <div class="!ml-auto"></div>

                    <div
                        class="p-2 bg-white rounded-lg cursor-pointer"
                        @click="handleExportExcel(detail_data, ranger_date)"
                    >
                        <el-tooltip
                            class="item"
                            :content="$t(`common.export_excel`)"
                            effect="dark"
                            placement="top"
                        >
                            <ExcelSvg></ExcelSvg>
                        </el-tooltip>
                    </div>
                </div>

                <div>
                    <el-table
                        :max-height="400"
                        :min-height="100"
                        :data="detail_data"
                    >
                        <el-table-column
                            width="160"
                            :show-overflow-tooltip="true"
                            :label="formatBySelectedDimension('label')"
                            :fixed="true"
                        >
                            <template #default="scope">
                                <p>
                                    {{
                                        formatBySelectedDimension(
                                            'name',
                                            scope.row.dimensions
                                        )
                                    }}
                                </p>
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-for="(item, index) in selected_metrics_for_table"
                            :key="index"
                            width="160"
                            :show-overflow-tooltip="true"
                            :prop="item"
                            :label="$t(`page.campaign.column_${item}`)"
                            sortable="custom"
                            align="right"
                        >
                            <template v-slot:header>
                                <el-tooltip
                                    class="item"
                                    :content="
                                        $t(`page.campaign.column_${item}`)
                                    "
                                    effect="dark"
                                    placement="top"
                                >
                                    <div slot="content" class="max-w-[360px]">
                                        <p
                                            class="text-sm"
                                            v-html="
                                                $t(
                                                    `page.campaign.column_${item}`
                                                )
                                            "
                                        ></p>

                                        <p
                                            v-if="
                                                $t(
                                                    `page.campaign.description_column_${item}`
                                                ) !==
                                                `page.campaign.description_column_${item}`
                                            "
                                            class="mt-1"
                                            v-html="
                                                $t(
                                                    `page.campaign.description_column_${item}`
                                                )
                                            "
                                        ></p>
                                    </div>

                                    <p>
                                        {{ $t(`page.campaign.column_${item}`) }}
                                    </p>
                                </el-tooltip>
                            </template>

                            <template #default="scope">
                                <p>
                                    {{
                                        m__formatterColumn(
                                            scope.row.metrics[item],
                                            item,
                                            currency
                                        )
                                    }}
                                </p>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div v-if="switch_compare" class="mt-2">
                <div class="flex items-center mb-2 space-x-2">
                    <div class="!ml-auto"></div>

                    <div
                        class="p-2 bg-white rounded-lg cursor-pointer"
                        @click="
                            handleExportExcel(
                                compare_detail_data,
                                compare_ranger_date
                            )
                        "
                    >
                        <el-tooltip
                            class="item"
                            :content="$t(`common.export_excel`)"
                            effect="dark"
                            placement="top"
                        >
                            <ExcelSvg></ExcelSvg>
                        </el-tooltip>
                    </div>
                </div>

                <div>
                    <el-table
                        :max-height="400"
                        :min-height="100"
                        :data="compare_detail_data"
                    >
                        <el-table-column
                            width="160"
                            :show-overflow-tooltip="true"
                            :label="formatBySelectedDimension('label')"
                            :fixed="true"
                        >
                            <template #default="scope">
                                <p>
                                    {{
                                        formatBySelectedDimension(
                                            'name',
                                            scope.row.dimensions
                                        )
                                    }}
                                </p>
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-for="(item, index) in selected_metrics_for_table"
                            :key="index"
                            width="160"
                            :show-overflow-tooltip="true"
                            :prop="item"
                            :label="$t(`page.campaign.column_${item}`)"
                            sortable="custom"
                            align="right"
                        >
                            <template v-slot:header>
                                <el-tooltip
                                    class="item"
                                    :content="
                                        $t(`page.campaign.column_${item}`)
                                    "
                                    effect="dark"
                                    placement="top"
                                >
                                    <div slot="content" class="max-w-[360px]">
                                        <p
                                            class="text-sm"
                                            v-html="
                                                $t(
                                                    `page.campaign.column_${item}`
                                                )
                                            "
                                        ></p>

                                        <p
                                            v-if="
                                                $t(
                                                    `page.campaign.description_column_${item}`
                                                ) !==
                                                `page.campaign.description_column_${item}`
                                            "
                                            class="mt-1"
                                            v-html="
                                                $t(
                                                    `page.campaign.description_column_${item}`
                                                )
                                            "
                                        ></p>
                                    </div>

                                    <p>
                                        {{ $t(`page.campaign.column_${item}`) }}
                                    </p>
                                </el-tooltip>
                            </template>

                            <template #default="scope">
                                <p>
                                    {{
                                        m__formatterColumn(
                                            scope.row.metrics[item],
                                            item,
                                            currency
                                        )
                                    }}
                                </p>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ExcelSvg from '@/assets/svgs/excel.svg'
import moment from 'moment'
import { exportExcel } from '@/services/atosa-tiktok-ads/reporting'
import { formatAge } from '@/views/campaign/campaign-wrapper/_components/view-data-drawer/_utils'
import { getSyncReport } from '@/services/atosa-tiktok-ads/reporting'
import campaignMixin from '@/views/campaign/campaign-wrapper/_mixin'

export default {
    components: {
        ExcelSvg
    },

    mixins: [campaignMixin],

    props: [
        'currency',
        'advertiser_id',
        'tiktok_account_id',
        'ranger_date',
        'format_date',
        'switch_compare',
        'compare_ranger_date',
        'compare_format_date',
        'region_options',
        'device_model_options',
        'interest_category_options'
    ],

    data() {
        return {
            selected_metrics_for_table: [],
            show_custom_column_dialog: false,
            dimension_options: [
                {
                    value: 'age',
                    label: this.$t('common.age')
                },
                {
                    value: 'gender',
                    label: this.$t('common.gender')
                },
                {
                    value: 'age,gender',
                    label: this.$t('common.age_and_gender')
                },
                {
                    value: 'country_code',
                    label: this.$t('common.country')
                },
                {
                    value: 'province_id',
                    label: this.$t('common.province')
                },
                {
                    value: 'ac',
                    label: this.$t('common.network')
                },
                {
                    value: 'interest_category',
                    label: this.$t('page.campaign.interests')
                },
                // {
                //     value: 'behavior_id',
                //     label: this.$t('common.behavior')
                // },
                {
                    value: 'platform',
                    label: this.$t('common.operating_system')
                },
                {
                    value: 'device_brand_id',
                    label: this.$t('common.device')
                }
            ],
            selected_dimension: null,
            detail_data: [],
            compare_detail_data: [],
            order_field: 'spend',
            order_type: 'DESC'
        }
    },

    watch: {
        ranger_date() {
            this.getOverview(true)
        },

        compare_ranger_date() {
            this.getCompareOverview(true)
        },

        selected_dimension() {
            this.getOverview(true)

            if (this.switch_compare) {
                this.getCompareOverview(true)
            }
        }
    },

    mounted() {
        setTimeout(() => {
            this.getOverview()
        }, 2000)

        this.selected_dimension = this.dimension_options[0].value

        this.selected_metrics_for_table = this.p__trend_basic_chart_options.map(
            (item) => item.value
        )
    },

    methods: {
        async getOverview(reset_sort = false) {
            const response = await this.fetchOverviewReport(
                this.ranger_date,
                reset_sort
            )

            this.detail_data = response
        },

        async getCompareOverview(reset_sort = false) {
            const response = await this.fetchOverviewReport(
                this.compare_ranger_date,
                reset_sort
            )

            this.compare_detail_data = response
        },

        async fetchOverviewReport(ranger_date, reset_sort = false) {
            if (!this.tiktok_account_id) {
                return
            }
            await this.p__wait(222)

            let detail_data = []
            this.m__loading = true

            try {
                const start_date = moment(ranger_date[0]).format('YYYY-MM-DD')
                const end_date = moment(ranger_date[1]).format('YYYY-MM-DD')

                if (reset_sort) {
                    this.order_field = 'spend'
                    this.order_type = 'DESC'
                }

                const response = await getSyncReport(this.tiktok_account_id, {
                    page: 1,
                    page_size: 100,
                    report_type: 'AUDIENCE',
                    data_level: 'AUCTION_ADVERTISER',
                    metrics: this.p__basic_metrics,
                    start_date,
                    end_date,
                    order_field: this.order_field,
                    order_type: this.order_type,
                    advertiser_id: this.advertiser_id,
                    dimensions: JSON.stringify(
                        this.selected_dimension.split(',')
                    )
                })
                if (response.code === 0 && response.data.list.length > 0) {
                    detail_data = response.data.list
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false

            return detail_data
        },

        async handleExportExcel(detail_data, ranger_date) {
            const headers = [
                this.formatBySelectedDimension('label'),
                ...this.p__basic_metrics.map((item) =>
                    this.$t(`page.campaign.column_${item}`)
                )
            ]

            const body = detail_data.map((item) => {
                return [
                    this.formatBySelectedDimension('name', item.dimensions),
                    ...this.p__basic_metrics.map((me) =>
                        this.m__formatterColumn(
                            item.metrics[me],
                            item,
                            this.currency
                        )
                    )
                ]
            })

            const start_time = moment(ranger_date[0]).format(this.format_date)
            const end_time = moment(ranger_date[1]).format(this.format_date)

            const name = `analyst_${this.formatBySelectedDimension(
                'label'
            )}_ad_${this.advertiser_id}_${start_time}_${end_time}`

            const response = await exportExcel({
                name,
                headers,
                body
            })

            this.p__exportExcelByBlob(response, name)
        },

        async handleSortChange({ column, prop, order }) {
            this.order_field = prop
            this.order_type = order === 'descending' ? 'DESC' : 'ASC'

            this.fetchOverviewReport()
        },

        formatBySelectedDimension(type, row = null) {
            if (this.selected_dimension === 'age') {
                if (type === 'label') {
                    return this.$t('common.age')
                }
                return formatAge(row['age'])
            }
            if (this.selected_dimension === 'gender') {
                if (type === 'label') {
                    return this.$t('common.gender')
                }
                return row['gender'] === 'MALE'
                    ? this.$t('common.male')
                    : this.$t('common.female')
            }
            if (this.selected_dimension === 'age,gender') {
                if (type === 'label') {
                    return this.$t('common.age_and_gender')
                }
                return `${formatAge(row['age'])} + ${
                    row['gender'] === 'MALE'
                        ? this.$t('common.male')
                        : this.$t('common.female')
                }`
            }
            if (this.selected_dimension === 'country_code') {
                if (type === 'label') {
                    return this.$t('common.country')
                }
                return row['country_code']
            }
            if (this.selected_dimension === 'province_id') {
                if (type === 'label') {
                    return this.$t('common.province')
                }

                if (this.region_options.length) {
                    const item = this.region_options.find(
                        (item) => item.region_id === row['province_id']
                    )
                    if (item) {
                        return item.region_name
                    }
                }
                return row['province_id']
            }
            if (this.selected_dimension === 'ac') {
                if (type === 'label') {
                    return this.$t('common.network')
                }
                return row['ac']
            }
            if (this.selected_dimension === 'interest_category') {
                if (type === 'label') {
                    return this.$t('page.campaign.interests')
                }
                if (this.interest_category_options.length) {
                    const item = this.interest_category_options.find(
                        (item) =>
                            item.interest_category_id ===
                            row['interest_category_v2']
                    )
                    if (item) {
                        return item.interest_category_name
                    }
                }
                return row['interest_category_v2']
            }
            if (this.selected_dimension === 'platform') {
                if (type === 'label') {
                    return this.$t('common.operating_system')
                }
                return row['platform']
            }
            if (this.selected_dimension === 'device_brand_id') {
                if (type === 'label') {
                    return this.$t('common.device')
                }
                if (this.device_model_options.length) {
                    const item = this.device_model_options.find(
                        (item) =>
                            item.device_model_id === row['device_brand_id']
                    )
                    if (item) {
                        return item.device_model_name
                    }
                }
                return row['device_brand_id']
            }
            // if (this.selected_dimension === 'behavior_id') {
            //     if (type === 'label') {
            //         return this.$t('common.behavior')
            //     }
            //     return row['behavior_id']
            // }

            return ''
        }
    }
}
</script>
