<template>
    <custom-drawer
        v-loading="m__loading"
        size="520px"
        :wrapper_closable="false"
        :confirm_to_close="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="items-center h-full flex pl-2 pr-8 space-x-4">
                <p class="text-lg font-semibold">Đề xuất chân dung KH</p>
            </div>
        </template>

        <div class="m-4 component-box">
            <el-date-picker
                v-model="ranger_date"
                class="!w-full"
                popper-class="custom-el-date"
                size="small"
                type="daterange"
                unlink-panels
                :start-placeholder="$t('date_picker.placeholder.start')"
                :end-placeholder="$t('date_picker.placeholder.end')"
                :format="$t('common.format_value_date_picker')"
                value-format="yyyy-MM-dd"
                :picker-options="m__picker_options"
            >
            </el-date-picker>

            <p class="mb-2 mt-4 font-semibold">Tiêu chí</p>

            <div
                v-for="metric in selected_metrics"
                :key="metric.order_field"
                class="flex items-center mb-1 space-x-8"
            >
                <el-select
                    v-model="metric.order_field"
                    filterable
                    class="w-full"
                    size="small"
                >
                    <el-option
                        v-for="item in p__trend_basic_chart_options"
                        :key="item.value"
                        :disabled="
                            selected_metrics.some(
                                (it) => it.order_field === item.value
                            )
                        "
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>

                <el-radio v-model="metric.order_type" :label="'DESC'"
                    >Cao</el-radio
                >
                <el-radio v-model="metric.order_type" :label="'ASC'"
                    >Thấp</el-radio
                >

                <i
                    v-show="selected_metrics.length > 1"
                    class="el-icon-close ml-2 cursor-pointer"
                    @click="
                        selected_metrics = selected_metrics.filter(
                            (it) => it.order_field !== metric.order_field
                        )
                    "
                ></i>
            </div>

            <div>
                <el-button
                    plain
                    :disabled="
                        selected_metrics.length ===
                        p__trend_basic_chart_options.length
                    "
                    icon="el-icon-plus"
                    size="small"
                    class="w-[120px] mt-2"
                    @click="handleAddCondi"
                >
                    Thêm tiêu chí
                </el-button>
            </div>

            <el-button
                plain
                type="primary"
                size="small"
                class="mt-4 w-full"
                @click="handleProposal"
            >
                Đề xuất
            </el-button>

            <div class="my-4 bg-border w-full h-[1px]"></div>

            <div v-if="proposal_object">
                <p>
                    Tuổi :
                    <b>
                        {{ findAgeName(proposal_object.age) }}
                    </b>
                </p>
                <p>
                    Giới tính :
                    <b>
                        {{ findGenderName(proposal_object.gender) }}
                    </b>
                </p>
                <p>
                    Mạng :
                    <b>
                        {{ findNetworkName(proposal_object.ac) }}
                    </b>
                </p>
                <p>
                    Hệ điều hành :
                    <b>
                        {{ findPlatformName(proposal_object.platform) }}
                    </b>
                </p>
                <p>
                    Thiết bị :
                    <b>
                        {{ findDeviceName(proposal_object.device_brand_id) }}
                    </b>
                </p>
                <!-- <p>
                Mối quan tâm :
                <b>
                    {{
                        findInterestCategoryName(
                            proposal_object.interest_category
                        )
                    }}
                </b>
            </p> -->
            </div>

            <div v-else>
                <p class="text-desc-text text-center">
                    Chưa có đối tượng phù hợp với tiêu chí
                </p>
            </div>
        </div>
    </custom-drawer>
</template>

<script>
import { getSyncReport } from '@/services/atosa-tiktok-ads/reporting'
import moment from 'moment'
import rangerDateMixin from '@/plugins/mixins/ranger-date'

export default {
    mixins: [rangerDateMixin],

    props: [
        'visible',
        'location_options',
        'device_model_options',
        'interest_category_options',
        'advertiser_id',
        'tiktok_account_id',
        'ranger_date'
    ],

    data() {
        return {
            proposal_object: null,
            selected_metrics: [
                {
                    order_type: 'DESC',
                    order_field: 'spend'
                }
            ],
            data_list: [],
            age_options: [
                {
                    title: '13-17',
                    value: 'AGE_13_17'
                },
                {
                    title: '18-24',
                    value: 'AGE_18_24'
                },
                {
                    title: '24-34',
                    value: 'AGE_25_34'
                },
                {
                    title: '35-44',
                    value: 'AGE_35_44'
                },
                {
                    title: '45-54',
                    value: 'AGE_45_54'
                },
                {
                    title: '55+',
                    value: 'AGE_55_100'
                }
            ]
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    methods: {
        handleAddCondi() {
            if (
                this.selected_metrics.length ===
                this.p__trend_basic_chart_options.length
            ) {
                return
            }

            let order_field = 'spend'

            this.p__trend_basic_chart_options.forEach((item) => {
                if (
                    this.selected_metrics.every(
                        (it) => it.order_field !== item.value
                    )
                ) {
                    order_field = item.value
                }
            })

            this.selected_metrics.push({
                order_type: 'DESC',
                order_field
            })
        },

        findNetworkName(ac) {
            if (ac === 'UNKNOWN') {
                return this.$t('common.unknown')
            }

            return ac
        },

        findPlatformName(plt) {
            if (plt === 'UNKNOWN') {
                return this.$t('common.unknown')
            }

            return plt
        },

        findAgeName(age) {
            if (age === 'NONE') {
                return this.$t('common.unknown')
            }
            const item = this.age_options.find((item) => item.value === age)
            if (item) {
                return item.title
            } else {
                return age
            }
        },

        findGenderName(name) {
            if (name === 'MALE') {
                return this.$t('common.male')
            }
            if (name === 'FEMALE') {
                return this.$t('common.female')
            }

            return this.$t('common.unknown')
        },

        findDeviceName(device_brand_id) {
            const item = this.device_model_options.find(
                (item) => item.device_model_id === device_brand_id
            )
            if (item) {
                return item.device_model_name
            } else {
                return this.$t('common.unknown')
            }
        },

        findInterestCategoryName(interest_category) {
            const item = this.interest_category_options.find(
                (item) => item.interest_category_id === interest_category
            )
            if (item) {
                return item.interest_category_name
            } else {
                return interest_category
            }
        },

        async handleProposal() {
            this.m__loading = true

            try {
                const data_list = []

                await Promise.all(
                    [
                        'ac',
                        'device_brand_id',
                        'platform',
                        'age',
                        'gender',
                        'interest_category',
                        'country_code',
                        'province_id'
                    ].map(async (dimenssion) => {
                        let temp = {
                            dimenssion
                        }

                        await Promise.all(
                            this.selected_metrics.map(async (metric) => {
                                const temp_dimen = await this.getDimension(
                                    metric.order_field,
                                    metric.order_type,
                                    dimenssion
                                )

                                temp = {
                                    ...temp,
                                    [metric.order_field]: temp_dimen
                                }
                            })
                        )

                        data_list.push(temp)
                    })
                )

                this.data_list = data_list

                let temp_object = {}

                data_list.forEach((item) => {
                    const count = {}
                    for (const key in item) {
                        const value = item[key]
                        count[value] = (count[value] || 0) + 1
                    }

                    let max_value = ''
                    let maxCount = 0.01
                    for (const key in count) {
                        if (
                            count[key] > maxCount ||
                            (count[key] === maxCount &&
                                item[max_value] === undefined)
                        ) {
                            max_value = key
                            maxCount = count[key]
                        }
                    }

                    temp_object = {
                        ...temp_object,
                        [item.dimenssion]: max_value
                    }
                })

                this.proposal_object = temp_object
            } catch (error) {
                this.proposal_object = null
                console.error(error)
            }

            this.m__loading = false
        },

        async getDimension(order_field, order_type, dimension) {
            if (!this.tiktok_account_id) {
                return
            }
            const start_date = moment(this.ranger_date[0]).format('YYYY-MM-DD')
            const end_date = moment(this.ranger_date[1]).format('YYYY-MM-DD')

            const response = await getSyncReport(this.tiktok_account_id, {
                page: 1,
                page_size: 1,
                report_type: 'AUDIENCE',
                data_level: 'AUCTION_ADVERTISER',
                metrics: this.p__trend_basic_chart_options.map(
                    (item) => item.value
                ),
                start_date,
                end_date,
                order_field,
                order_type,
                advertiser_id: this.advertiser_id,
                dimensions: [dimension]
            })
            if (response.code === 0 && response.data.list.length > 0) {
                const temp_dimen =
                    dimension === 'interest_category'
                        ? 'interest_category_id'
                        : dimension
                return response.data.list[0].dimensions[temp_dimen]
            }

            return ''
        }
    }
}
</script>
