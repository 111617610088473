var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"border-lg p-4 rounded-lg bg-[#f4f4f4]"},[_c('div',[_c('div',{staticClass:"flex items-center mb-2 space-x-2"},[_c('el-select',{staticClass:"w-[200px]",attrs:{"size":"small"},model:{value:(_vm.selected_dimension),callback:function ($$v) {_vm.selected_dimension=$$v},expression:"selected_dimension"}},_vm._l((_vm.dimension_options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('div',{staticClass:"!ml-auto"}),_c('div',{staticClass:"p-2 bg-white rounded-lg cursor-pointer",on:{"click":function($event){return _vm.handleExportExcel(_vm.detail_data, _vm.ranger_date)}}},[_c('el-tooltip',{staticClass:"item",attrs:{"content":_vm.$t(`common.export_excel`),"effect":"dark","placement":"top"}},[_c('ExcelSvg')],1)],1)],1),_c('div',[_c('el-table',{attrs:{"max-height":400,"min-height":100,"data":_vm.detail_data}},[_c('el-table-column',{attrs:{"width":"160","show-overflow-tooltip":true,"label":_vm.formatBySelectedDimension('label'),"fixed":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s(_vm.formatBySelectedDimension( 'name', scope.row.dimensions ))+" ")])]}}])}),_vm._l((_vm.selected_metrics_for_table),function(item,index){return _c('el-table-column',{key:index,attrs:{"width":"160","show-overflow-tooltip":true,"prop":item,"label":_vm.$t(`page.campaign.column_${item}`),"sortable":"custom","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-tooltip',{staticClass:"item",attrs:{"content":_vm.$t(`page.campaign.column_${item}`),"effect":"dark","placement":"top"}},[_c('div',{staticClass:"max-w-[360px]",attrs:{"slot":"content"},slot:"content"},[_c('p',{staticClass:"text-sm",domProps:{"innerHTML":_vm._s(
                                            _vm.$t(
                                                `page.campaign.column_${item}`
                                            )
                                        )}}),(
                                            _vm.$t(
                                                `page.campaign.description_column_${item}`
                                            ) !==
                                            `page.campaign.description_column_${item}`
                                        )?_c('p',{staticClass:"mt-1",domProps:{"innerHTML":_vm._s(
                                            _vm.$t(
                                                `page.campaign.description_column_${item}`
                                            )
                                        )}}):_vm._e()]),_c('p',[_vm._v(" "+_vm._s(_vm.$t(`page.campaign.column_${item}`))+" ")])])]},proxy:true},{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s(_vm.m__formatterColumn( scope.row.metrics[item], item, _vm.currency ))+" ")])]}}],null,true)})})],2)],1)]),(_vm.switch_compare)?_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"flex items-center mb-2 space-x-2"},[_c('div',{staticClass:"!ml-auto"}),_c('div',{staticClass:"p-2 bg-white rounded-lg cursor-pointer",on:{"click":function($event){return _vm.handleExportExcel(
                            _vm.compare_detail_data,
                            _vm.compare_ranger_date
                        )}}},[_c('el-tooltip',{staticClass:"item",attrs:{"content":_vm.$t(`common.export_excel`),"effect":"dark","placement":"top"}},[_c('ExcelSvg')],1)],1)]),_c('div',[_c('el-table',{attrs:{"max-height":400,"min-height":100,"data":_vm.compare_detail_data}},[_c('el-table-column',{attrs:{"width":"160","show-overflow-tooltip":true,"label":_vm.formatBySelectedDimension('label'),"fixed":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s(_vm.formatBySelectedDimension( 'name', scope.row.dimensions ))+" ")])]}}],null,false,473572978)}),_vm._l((_vm.selected_metrics_for_table),function(item,index){return _c('el-table-column',{key:index,attrs:{"width":"160","show-overflow-tooltip":true,"prop":item,"label":_vm.$t(`page.campaign.column_${item}`),"sortable":"custom","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-tooltip',{staticClass:"item",attrs:{"content":_vm.$t(`page.campaign.column_${item}`),"effect":"dark","placement":"top"}},[_c('div',{staticClass:"max-w-[360px]",attrs:{"slot":"content"},slot:"content"},[_c('p',{staticClass:"text-sm",domProps:{"innerHTML":_vm._s(
                                            _vm.$t(
                                                `page.campaign.column_${item}`
                                            )
                                        )}}),(
                                            _vm.$t(
                                                `page.campaign.description_column_${item}`
                                            ) !==
                                            `page.campaign.description_column_${item}`
                                        )?_c('p',{staticClass:"mt-1",domProps:{"innerHTML":_vm._s(
                                            _vm.$t(
                                                `page.campaign.description_column_${item}`
                                            )
                                        )}}):_vm._e()]),_c('p',[_vm._v(" "+_vm._s(_vm.$t(`page.campaign.column_${item}`))+" ")])])]},proxy:true},{key:"default",fn:function(scope){return [_c('p',[_vm._v(" "+_vm._s(_vm.m__formatterColumn( scope.row.metrics[item], item, _vm.currency ))+" ")])]}}],null,true)})})],2)],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }