<template>
    <div class="flex flex-wrap items-center gap-4">
        <div
            v-for="(metric, index) in selected_metrics"
            :key="index"
            class="component-box w-[280px]"
        >
            <div class="flex items-center h-6 mb-2">
                <el-select
                    :value="metric"
                    class="el-select-border-transparent w-full"
                    size="small"
                    @change="(val) => handleChangeMetric(val, index)"
                >
                    <el-option-group
                        v-for="(group, idex) in p__view_data_metric_options"
                        :key="idex"
                        :label="group.label"
                    >
                        <el-option
                            v-for="(item, idx) in group.options"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-option-group>
                    <el-option-group :label="$t('common.shop_ads')">
                        <el-option
                            v-for="(item, idx) in p__trend_onsite_options"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-option-group>
                </el-select>
            </div>

            <div class="flex items-center">
                <p class="mr-auto text-xl font-semibold">
                    {{ $t('common.lifetime') }}
                </p>
                <span class="text-xl font-semibold">
                    {{
                        total_overview_lifetime
                            ? p__formatNumberHasCommas(
                                  total_overview_lifetime[metric]
                              )
                            : 0
                    }}
                </span>
            </div>

            <div class="flex items-center">
                <p class="mr-auto">{{ $t('common.today') }}</p>
                <span class="">
                    {{
                        total_overview_today
                            ? p__formatNumberHasCommas(
                                  total_overview_today[metric]
                              )
                            : 0
                    }}
                </span>
            </div>

            <div class="flex items-center mt-1">
                <p class="mr-auto text-sm text-desc-text">
                    {{ $t('common.yesterday') }}
                </p>
                <span class="text-sm text-desc-text">
                    {{
                        total_overview_yesterday
                            ? p__formatNumberHasCommas(
                                  total_overview_yesterday[metric]
                              )
                            : 0
                    }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { getSyncReport } from '@/services/atosa-tiktok-ads/reporting'

export default {
    props: ['ads_accounts', 'tiktok_account'],

    data() {
        const metrics = []
        this.p__view_data_metric_options.forEach((item) => {
            item.options.forEach((it) => {
                metrics.push(it.value)
            })
        })

        this.p__trend_onsite_options.forEach((it) => {
            metrics.push(it.value)
        })

        return {
            selected_metrics: this.p__basic_metrics.slice(0, 5),
            valuee: '',
            total_overview_today: null,
            total_overview_yesterday: null,
            total_overview_lifetime: null,
            metrics
        }
    },

    computed: {
        s__currency() {
            return this.$store.getters.app.s__currency
        }
    },

    watch: {
        ads_accounts() {
            setTimeout(() => {
                this.fetchOverviewReportYesterday()

                this.fetchOverviewReportToday()

                this.fetchOverviewReportLifetime()
            }, 1234)
        }
    },

    mounted() {
        const temp = localStorage.getItem('dashboard_general')
        if (temp) {
            this.selected_metrics = JSON.parse(temp)
        }

        setTimeout(() => {
            this.fetchOverviewReportYesterday()

            this.fetchOverviewReportToday()

            this.fetchOverviewReportLifetime()
        }, 1234)
    },

    methods: {
        handleChangeMetric(val, index) {
            this.selected_metrics = this.selected_metrics.map((value, idx) => {
                if (index === idx) {
                    return val
                }

                return value
            })

            localStorage.setItem(
                'dashboard_general',
                JSON.stringify(this.selected_metrics)
            )
        },

        async fetchOverviewReport(
            start_date,
            end_date,
            query_lifetime = false
        ) {
            if (!this.tiktok_account || this.ads_accounts.length === 0) {
                return null
            }

            this.m__loading = true

            try {
                const response_list = []

                await Promise.all(
                    this.ads_accounts.map(async (item) => {
                        const data = {
                            report_type: 'BASIC',
                            metrics: this.metrics,
                            start_date,
                            end_date,
                            service_type: 'AUCTION',
                            data_level: 'AUCTION_ADVERTISER',
                            advertiser_id: item.advertiser_id,
                            dimensions: ['advertiser_id'],
                            query_lifetime
                        }

                        const response = await getSyncReport(
                            this.tiktok_account.id,
                            data
                        )
                        if (
                            response.code === 0 &&
                            response.data.list.length > 0
                        ) {
                            response_list.push(response.data.list[0].metrics)
                        }
                    })
                )
                const result = this.p__calculateMetrics(response_list)

                this.m__loading = false

                return result
            } catch (error) {
                this.m__loading = false

                console.error(error)
            }

            return null
        },

        async fetchOverviewReportYesterday() {
            const start_date = moment().subtract(1, 'd').format('YYYY-MM-DD')
            const end_date = moment().subtract(1, 'd').format('YYYY-MM-DD')

            const response = await this.fetchOverviewReport(
                start_date,
                end_date
            )
            if (response) {
                this.total_overview_yesterday = response
            }
        },

        async fetchOverviewReportToday() {
            const start_date = moment().format('YYYY-MM-DD')
            const end_date = moment().format('YYYY-MM-DD')

            const response = await this.fetchOverviewReport(
                start_date,
                end_date
            )
            if (response) {
                this.total_overview_today = response
            }
        },

        async fetchOverviewReportLifetime() {
            const start_date = moment().format('YYYY-MM-DD')
            const end_date = moment().format('YYYY-MM-DD')
            const response = await this.fetchOverviewReport(
                start_date,
                end_date,
                true
            )

            if (response) {
                this.total_overview_lifetime = response
            }
        }
    }
}
</script>
