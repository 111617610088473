<template>
    <div>
        <div class="flex items-center mb-2">
            <div
                class="bg-bg p-2 ml-auto rounded-lg cursor-pointer"
                @click="handleExportExcel(new_data_list, ranger_date)"
            >
                <el-tooltip
                    class="item"
                    :content="$t(`common.export_excel`)"
                    effect="dark"
                    placement="top"
                >
                    <ExcelSvg></ExcelSvg>
                </el-tooltip>
            </div>
        </div>

        <el-table
            max-height="360"
            :data="data_overview"
            @sort-change="handleSortChange"
        >
            <el-table-column
                width="240"
                :show-overflow-tooltip="true"
                :label="$t('common.ad_account')"
                :fixed="true"
            >
                <template #default="scope">
                    <p>ID: {{ scope.row.advertiser_id }}</p>
                    <p>
                        {{ scope.row.name }}
                    </p>
                </template>
            </el-table-column>

            <el-table-column
                v-for="(item, index) in p__trend_basic_chart_options"
                :key="index"
                width="160"
                :show-overflow-tooltip="true"
                :prop="item.value"
                :label="$t(`page.campaign.column_${item.value}`)"
                sortable="custom"
                align="right"
            >
                <template v-slot:header>
                    <el-tooltip
                        class="item"
                        :content="$t(`page.campaign.column_${item.value}`)"
                        effect="dark"
                        placement="top"
                    >
                        <div slot="content" class="max-w-[360px]">
                            <p
                                class="text-sm"
                                v-html="
                                    $t(`page.campaign.column_${item.value}`)
                                "
                            ></p>

                            <p
                                v-if="
                                    $t(
                                        `page.campaign.description_column_${item.value}`
                                    ) !==
                                    `page.campaign.description_column_${item.value}`
                                "
                                class="mt-1"
                                v-html="
                                    $t(
                                        `page.campaign.description_column_${item.value}`
                                    )
                                "
                            ></p>
                        </div>

                        <p>
                            {{ $t(`page.campaign.column_${item.value}`) }}
                        </p>
                    </el-tooltip>
                </template>

                <template #default="scope">
                    <p>
                        {{
                            m__formatterColumn(
                                scope.row.metrics[item.value],
                                item.value,
                                currency
                            )
                        }}
                    </p>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import campaignMixin from '@/views/campaign/campaign-wrapper/_mixin'
import ExcelSvg from '@/assets/svgs/excel.svg'
import { exportExcel } from '@/services/atosa-tiktok-ads/reporting'
import moment from 'moment'

export default {
    components: {
        ExcelSvg
    },

    mixins: [campaignMixin],

    props: ['data_overview', 'currency', 'ranger_date'],

    methods: {
        handleSortChange({ column, prop, order }) {
            if (order === 'descending') {
                this.data_overview.sort((a, b) =>
                    +a.metrics[prop] > +b.metrics[prop]
                        ? 1
                        : +b.metrics[prop] > +a.metrics[prop]
                        ? -1
                        : 0
                )
            } else {
                this.data_overview.sort((a, b) =>
                    +a.metrics[prop] < +b.metrics[prop]
                        ? 1
                        : +b.metrics[prop] < +a.metrics[prop]
                        ? -1
                        : 0
                )
            }
        },

        async handleExportExcel() {
            const headers = [
                'ID',
                this.$t('common.ad_account'),
                ...this.p__trend_basic_chart_options.map((item) =>
                    this.$t(`page.campaign.column_${item.value}`)
                )
            ]

            const body = this.data_overview.map((item) => {
                return [
                    item.advertiser_id,
                    item.name,
                    ...this.p__trend_basic_chart_options.map((it) =>
                        this.m__formatterColumn(
                            item.metrics[it.value],
                            it.value,
                            this.currency
                        )
                    )
                ]
            })

            const start_time = moment(this.ranger_date[0]).format(
                this.$t('common.format_date')
            )
            const end_time = moment(this.ranger_date[1]).format(
                this.$t('common.format_date')
            )

            const name = `ad_accounts_${start_time}_${end_time}`

            const response = await exportExcel({
                name,
                headers,
                body
            })

            this.p__exportExcelByBlob(response, name)
        }
    }
}
</script>
